import { env } from '@mdca/wde';

export const NODE_VARS = {
  // COMMON
  SERVER_PORT: Number.parseInt(env('SERVER_PORT'), 10),
  NODE_ENV: env('NODE_ENV'),
  PLATFORM_URL: env('PLATFORM_URL'),
  LANDING_URL: env('LANDING_URL'),
  // SENTRY
  SENTRY_DSN: env('SENTRY_DSN'),
  // WIDGET
  WIDGET_URL: env('WIDGET_URL'),
};
