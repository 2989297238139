import React from 'react';
import Stack from '@mui/system/Stack';

type StepsWidgetProps = {
  leftSide?: JSX.Element;
  rightSide?: JSX.Element;
};

export function StepsWidget({ leftSide, rightSide }: StepsWidgetProps) {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        maxHeight: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        gap: { xs: '20px', lg: '100px' },
      }}
    >
      <Stack
        sx={{
          flexGrow: 1,
          display: 'flex',
          gap: { xs: '24px', lg: '56px' },
          flexDirection: 'column',
          justifyContent: 'center',
          flexBasis: '50%',
        }}
      >
        {leftSide}
      </Stack>
      <Stack
        sx={{
          flexGrow: 1,
          flexBasis: '50%',
          borderRadius: '8px',
        }}
      >
        {rightSide}
      </Stack>
    </Stack>
  );
}
