import React from 'react';
import { isMobile } from 'react-device-detect';
import { Navigate, useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { renameFile, transliterateFileName } from '@core/shared';
import { useForm } from '@core/web/hooks';
import { LoadingBackdrop, MdTypography, TextField } from '@core/web/look';
import { NODE_VARS } from '@metadialog/landing-chat-config';
import { useSelector } from '@wpa/redux-utils';

import { checkEmail, sentConfirmation, uploadFiles } from '@app/api';
import { AppState, State } from '@app/store';
import { ResourceType } from '@app/store/types';

import { MainCard } from '../MainCard';

import { CircularProgressWithLabel } from './CircularProgressWithLabel';

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
});

export function GetEmail() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);

  const { uploadedResources } = useSelector<AppState, State>((state) => state?.app);
  const initialValues = React.useMemo(() => ({ email: '' }), []);
  const formik = useForm<typeof initialValues, typeof validationSchema>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const { email } = values;

      try {
        setIsLoading(true);
        const { isExists, isActive } = await checkEmail(email, '');
        setIsLoading(false);

        // TODO check uploaded resources count and upload if needed

        if (!isExists) {
          await sentConfirmation(email);

          const event = new CustomEvent('testmybot-click', { bubbles: true, cancelable: true });
          window.dispatchEvent(event);

          const formData = new FormData();

          // eslint-disable-next-line no-restricted-syntax
          for (const resource of uploadedResources) {
            if (resource.type === ResourceType.link) {
              const blob = new Blob([new TextEncoder().encode(JSON.stringify(resource))], {
                type: 'application/json;charset=utf-8',
              });

              formData.append(resource.name, blob);
            } else {
              const newName = transliterateFileName(resource.name);
              formData.append(newName, renameFile(resource as File, newName));
            }
          }

          setIsLoading(true);
          await uploadFiles(email, formData);
          setIsLoading(false);

          navigate('confirm', { state: { email } });
        } else if (isExists && !isActive) {
          enqueueSnackbar(
            <Box>
              <Typography>You need to verify your email!</Typography>
            </Box>,
            { variant: 'error' },
          );
        } else if (isExists && isActive) {
          window.location.assign(NODE_VARS.PLATFORM_URL);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
        setIsLoading(false);
      }
    },
  });

  if (isEmpty(uploadedResources)) {
    return <Navigate to="/steps/1" />;
  }

  return (
    <MainCard name="email" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} columns={6}>
        {isMobile && (
          <Grid xs={6} justifyContent="center" sx={{ display: 'flex' }}>
            <CircularProgressWithLabel value={75} style={{ width: '250px', height: '250px' }} />
          </Grid>
        )}
        <Grid xs={6}>
          <MdTypography variant="h4">Enter email to test bot</MdTypography>
        </Grid>
        <Grid xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid xs={6}>
          <Button type="submit" fullWidth size="large">
            Test my Bot
          </Button>
        </Grid>
      </Grid>
      <LoadingBackdrop loading={isLoading} />
    </MainCard>
  );
}
