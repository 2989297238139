import axios from 'axios';

import { encodeBase64, renameFile, transliterateFileName } from '@core/shared';
import { NODE_VARS } from '@metadialog/landing-chat-config';

import { Resource, ResourceType } from '@app/store/types';

export async function checkEmail<
  T extends Record<string, any> = { isExists: boolean; isConfirmed: boolean; isActive: boolean },
>(email: string, query: string): Promise<T> {
  const lowerCaseEmail = String(email).toLowerCase();

  const { data } = await axios.post<T>(`${NODE_VARS.PLATFORM_URL}/api/landing/check-email`, {
    email: lowerCaseEmail,
    query,
  });

  return data;
}

export async function sentConfirmation(email: string) {
  const lowerCaseEmail = String(email).toLowerCase();

  return axios.post(`${NODE_VARS.PLATFORM_URL}/api/landing/sent-confirmation`, { email: lowerCaseEmail });
}

export async function uploadFiles(email: string, formData: FormData) {
  const lowerCaseEmail = String(email).toLowerCase();

  const { data } = await axios.post(`${NODE_VARS.PLATFORM_URL}/api/landing/upload-files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Upload-Token': encodeBase64(lowerCaseEmail),
    },
  });

  return data;
}

export async function uploadResources(email: string, resources: Resource[]) {
  const formData = new FormData();

  for (const resource of resources) {
    if (resource.type === ResourceType.link) {
      const blob = new Blob([new TextEncoder().encode(JSON.stringify(resource))], {
        type: 'application/json;charset=utf-8',
      });

      formData.append(resource.name, blob);
    } else {
      const newName = transliterateFileName(resource.name);
      formData.append(newName, renameFile(resource as File, newName));
    }
  }

  return uploadFiles(email, formData);
}

export async function uploadDocuments(platformUserId: string, channelKey: string, resources: Resource[]) {
  const formData = new FormData();
  formData.append('platformUserId', platformUserId);
  formData.append('connectorKey', channelKey);

  for (const resource of resources) {
    if (resource.type !== ResourceType.link) {
      const newName = transliterateFileName(resource.name);
      formData.append(newName, renameFile(resource as File, newName));
    }
  }

  const { data } = await axios.post(`${NODE_VARS.PLATFORM_URL}/api/landing/documents`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
}

export async function registration(email: string, password: string) {
  const { data } = await axios.post(`${NODE_VARS.PLATFORM_URL}/api/landing/registration`, { email, password });

  return data;
}

export async function sendUserMessage(userID: string, message: string, channelKey: string, clientData: string) {
  const { data } = await axios.post(`${NODE_VARS.PLATFORM_URL}/api/landing/send-user-files`, {
    inputText: message,
    userId: userID,
    connectorsKey: channelKey,
    clientData,
  });

  return data;
}
