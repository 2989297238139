import React from 'react';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import Box from '@mui/material/Box';
import Stack from '@mui/system/Stack';

import { getClientData } from '@app/helpers/WidgetHelper';

import { CHANNEL_KEY, CHAT_BOT_READY, INITIAL_HTML } from './constants';

export const getWidgetConfig = (widgetUserID: string) => ({
  extendedMode: false,
  userID: widgetUserID,
  isFullScreen: true,
  isEnabled: true,
  staticImages: {
    logoUrl: ' ',
  },
  elementSizes: {
    logoSize: '0px',
    headerHeight: null,
    windowBorderRadius: '8px',
  },
  paletteType: 'dark',
  paletteTypes: {
    dark: {
      bodyBackground: '#232F48',
      inputColor: '#232F48',
      inputBorderColor: '#7BB3F8',
      inputTextColor: '#CED9E9',
      inputPlaceholderColor: '#CED9E9',
      inputCancelButtonColor: '#7BB3F8',
      sendButtonIconColor: '#7BB3F8',

      /* --------Body-------- */
      typingDotBackgroundColor: '#687DA1',
      typingTextColor: '#687DA1',

      /* --------Date-------- */
      dateBackground: '#4E6185',
      dateText: '#adaeb2',

      /* --------User message-------- */
      userTimeColor: '#CED9E9',
      messageColor: '#CED9E9',
      messageBackground: '#2C3B58',
      messageBorderColor: 'transparent',
      messageIconBackground: '#2C3B58',
      /* --------Bot message-------- */
      messageColorBot: '#CED9E9',
      botNameTimeColor: '#CED9E9',
      messageBackgroundBot: '#2C3B58',
      messageBorderColorBot: '#2C3B58',
      messageIconBackgroundBot: '#2C3B58',
    },
  },
});

const randomUserID = Math.random().toString(36).slice(7);

const setUserId = (userId: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set('target', userId);

  window.location.replace(url);
};

const getWidgetUserID = () => {
  const url = new URL(window.location.href);
  const userId = url.searchParams.get('target');

  if (!userId) {
    setUserId(randomUserID);
  }

  return userId || randomUserID;
};

export function ChatWidget() {
  const isRunning = React.useRef(false);
  const iframeWindow = React.useRef<Window>();
  const widgetUserID = getWidgetUserID();

  return (
    <Stack sx={{ height: '100%' }}>
      <Box
        component={Frame}
        initialContent={INITIAL_HTML}
        mountTarget="#mountHere"
        contentDidUpdate={() => {
          if (!isRunning.current && CHANNEL_KEY && iframeWindow.current && 'SmartChatBotApp' in iframeWindow.current) {
            setTimeout(() => {
              if (!isRunning.current) {
                isRunning.current = true;
                // @ts-expect-error: noop
                iframeWindow.current.SmartChatBotApp.init({
                  externalConfig: getWidgetConfig(widgetUserID),
                  key: CHANNEL_KEY,
                  clientData: getClientData(),
                });
              }
            }, 500);
          }
        }}
        sx={{
          minWidth: '100%',
          border: 0,
          minHeight: '400px',
          flexGrow: 1,
        }}
      >
        <FrameContextConsumer>
          {({ window: win }) => {
            iframeWindow.current = win;

            win?.addEventListener(CHAT_BOT_READY, () => {
              if (CHANNEL_KEY && win && 'SmartChatBotApp' in win) {
                setTimeout(() => {
                  if (!isRunning.current) {
                    isRunning.current = true;
                    // @ts-expect-error: noop
                    win.SmartChatBotApp.init({
                      externalConfig: getWidgetConfig(widgetUserID),
                      key: CHANNEL_KEY,
                      clientData: getClientData(),
                    });
                  }
                }, 500);
              } else {
                console.error('No connection to SmartChatBotApp');
              }
            });

            return <div />;
          }}
        </FrameContextConsumer>
      </Box>
      {/* <UploadFilesForm channelKey={channelKey} /> */}
    </Stack>
  );
}
