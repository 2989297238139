import React from 'react';
import { Outlet } from 'react-router';
import Stack from '@mui/material/Stack';

import { MdBox } from '@core/web/look';

export function Root() {
  return (
    <MdBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: { xs: 'initial', lg: 'inherit' },
        maxHeight: { xs: 'inherit', lg: '100vh' },
        background: '#111827',
      }}
    >
      <Stack sx={{ flexGrow: 1, height: { lg: '1px' }, maxHeight: '100%', padding: { xs: '16px', lg: '32px 32px' } }}>
        <Outlet />
      </Stack>
    </MdBox>
  );
}
