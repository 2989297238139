/* eslint-disable no-plusplus */
// eslint-disable-next-line camelcase
import React from 'react';
import { unstable_batchedUpdates as batch } from 'react-dom';

import { Selector } from '../createSelector';

import shallowEqual from './utils/shallowEqual';

export type ActionFunc = () => void;
export type BaseAction = { type: string; meta?: any };
export type Action<P> = { payload: P } & BaseAction;
export type MaybeThunkAction<T> = BaseAction | Action<T> | ActionFunc;
export type Reducer<S, A> = (state: S | undefined, action: A) => S;
export type Dispatch<S, A> = {
  reducerName?: string;
  selector?: Selector<S>;
  equalityFn?: (a: any, b: any) => boolean;
  result?: React.MutableRefObject<Partial<S>>;
  reducer?: Reducer<S, A>;
  render: React.Dispatch<React.SetStateAction<S>>;
};
type Context<S, A> = {
  state: S;
  dispatch: Map<number, Dispatch<S, A> | React.Dispatch<React.SetStateAction<S>>>;
};

let counter = 1;
const genKey = () => counter++;
let cache: Record<string, any> = {};
let reducerActiveComponent = new Map<string, number>();
const context: Context<any, any> = {
  state: {},
  dispatch: new Map(),
};

Object.seal(context);

function callReducerDispatch<S, A>(disp: Dispatch<S, A>, action: A) {
  const { reducerName, reducer, render } = disp;

  if (reducer && reducerName) {
    const state = context.state[reducerName];
    const nextState = reducer(state, action);

    if (!shallowEqual(state, nextState)) {
      context.state[reducerName] = nextState;

      render(nextState);
    }
  }
}

function callSelectorDispatch<S, A>(disp: Dispatch<S, A>) {
  const { selector, equalityFn, result, render } = disp;

  if (selector && result && equalityFn) {
    const nextResult = selector(context.state);

    if (!equalityFn(result.current, nextResult)) {
      result.current = nextResult;

      render(nextResult);
    }
  }
}

function callDispatch<S, A>(disp: Dispatch<S, A>, action: A) {
  if (disp?.reducerName) {
    callReducerDispatch<S, A>(disp, action);
  } else {
    callSelectorDispatch<S, A>(disp);
  }
}

export function dispatch<S, A extends MaybeThunkAction<any>>(action: A): void {
  if (typeof action === 'function') {
    action();
  } else if (!action.type) {
    throw new Error('Wrong action format.');
  }

  batch(() => {
    context.dispatch.forEach((disp) => {
      callDispatch<S, A>(disp as Dispatch<S, A>, action);
    });
  });
}

export function useReducer<S, A extends MaybeThunkAction<any>>(
  reducerName: string,
  reducer: Reducer<S, A>,
  initialState: S,
  options = { cache: true },
): [S, (action: A) => void] {
  if (!reducerName) {
    throw new Error('useReducer name argument(1) is required.');
  }
  if (!reducer) {
    throw new Error('useReducer argument(2) is required.');
  }
  if (!initialState) {
    throw new Error('initialState argument(3) is required.');
  }

  const key = React.useRef<number | undefined>();
  if (!key.current) {
    key.current = genKey();
    reducerActiveComponent.set(reducerName, key.current);
  } else if (reducerActiveComponent.get(reducerName) !== key.current) {
    throw new Error(`Looks like you're trying to use more than one component with the same "${reducerName}" reducer.`);
  }

  const [state, render] = React.useState<S>((options.cache && cache[reducerName]) || initialState);
  context.state[reducerName] = state;

  if (options.cache) {
    cache[reducerName] = state;
  }
  if (!context.dispatch.has(key.current)) {
    // TODO FIX render type
    context.dispatch.set(key.current, {
      reducerName,
      reducer,
      render,
    });
  }

  React.useEffect(
    () => () => {
      if (key.current != null) {
        context.dispatch.delete(key.current);
      }

      if (reducerActiveComponent.get(reducerName) === key.current) {
        delete context.state[reducerName];
      }
    },
    [reducerName],
  );

  const wrappedDispatch = (action: A): void => dispatch<S, A>(action);

  return [state, wrappedDispatch];
}

function refEquality(prev, next) {
  return prev === next;
}

export function useSelector<S, T extends Record<string, any>>(selector: Selector<T>, equalityFn = refEquality): T {
  if (typeof selector !== 'function') {
    throw new TypeError('Selector must be a function.');
  }
  if (equalityFn && typeof equalityFn !== 'function') {
    throw new Error('Equality function must be a function.');
  }

  const key = React.useRef<number | undefined>();
  if (!key.current) {
    key.current = genKey();
  }
  const initState = React.useRef<Partial<S>>();
  if (!initState.current) {
    initState.current = selector(context.state);
  }
  const [state, render] = React.useState<Partial<S>>(initState.current);
  const result = React.useRef<Partial<S>>();
  result.current = state;

  if (!context.dispatch.has(key.current)) {
    // TODO FIX render type
    context.dispatch.set(key.current, {
      selector,
      equalityFn,
      // @ts-ignore
      result,
      render,
    });
  }

  React.useEffect(
    () => () => {
      if (key.current != null) {
        context.dispatch.delete(key.current);
      }
    },
    [],
  );

  // @ts-ignore
  return state;
}

export function createUseReducer<S, A extends MaybeThunkAction<any> = Action<any>>(
  reducerName: string,
  reducer: Reducer<S, A>,
  options = { cache: true },
) {
  return (initialState: S) => useReducer<S, A>(reducerName, reducer, initialState, options);
}

//----------------------------------
//   DANGEROUS ZONE!!!
//   FOR TESTING PURPOSE ONLY
//----------------------------------

export function getState() {
  return context.state;
}

export function reset() {
  counter = 1;
  cache = {};
  context.state = {};
  context.dispatch = new Map();
  reducerActiveComponent = new Map<string, number>();
}
