import React from 'react';
import { RouterProvider, useRouteError } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import { MdBox } from '@core/web/look';

import { FinishingCard, ProcessingCard } from '@app/components/onboarding/Cards';
import { ChatWidget } from '@app/components/onboarding/ChatWidget';
import {
  FinishingStepDescription,
  ProcessingStepDescription,
  UploadingStepDescription,
} from '@app/components/onboarding/Descriptions';
import { UploadFilesForm, UserEmailForm } from '@app/components/onboarding/Forms';

import { StepsWidget } from './components/StepsWidget';
import { NavigateWithQuery } from './helpers/NavigateWithQuery';
import { Root } from './components';

function ErrorPage() {
  const error = useRouteError() as { statusText: string; message: string };
  console.error(error);

  return (
    <MdBox id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </MdBox>
  );
}

export function Router() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/',
          element: <NavigateWithQuery to="/steps/1" />,
        },
        {
          path: '/steps',
          element: <NavigateWithQuery to="/steps/1" />,
        },
        {
          path: '/steps/1',
          element: (
            <StepsWidget
              leftSide={
                <UploadingStepDescription isActive>
                  <UploadFilesForm />
                </UploadingStepDescription>
              }
              rightSide={<ChatWidget />}
            />
          ),
        },
        {
          path: '/steps/2',
          element: (
            <StepsWidget
              leftSide={
                <>
                  <UploadingStepDescription isActive={false} />
                  <ProcessingStepDescription isActive />
                </>
              }
              rightSide={
                <ProcessingCard sx={{ backgroundColor: '#192337' }}>
                  <UserEmailForm />
                </ProcessingCard>
              }
            />
          ),
        },
        {
          path: '/steps/confirm',
          element: <StepsWidget leftSide={<FinishingStepDescription />} rightSide={<FinishingCard />} />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}
