import React from 'react';
import { useLocation } from 'react-router';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinkOutlined from '@mui/icons-material/LinkOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

import { isUrlValid } from '@core/shared';
import { LoadingBackdrop } from '@core/web/look';
import { MultiFileUploader } from '@core/web/metadialog-shared';
import { NODE_VARS } from '@metadialog/landing-chat-config';
import { useSelector } from '@wpa/redux-utils';

import { checkEmail, sendUserMessage, sentConfirmation, uploadDocuments, uploadResources } from '@app/api';
import { useNavigateWithQuery } from '@app/helpers/useNavigateWithQuery';
import { AppState, setUserEmail, State } from '@app/store';

import { CHANNEL_KEY } from './constants';
import { getClientData } from '@app/helpers/WidgetHelper';

const UrlDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#192337',
    backgroundImage: 'none',
  },
  '& .MuiDialogActions-root': {},
}));

const getUserID = (userID?: string) => {
  const url = new URL(window.location.href);
  const userId = url.searchParams.get('target');

  return (userID || userId || '').replaceAll('"', '');
};

export function UploadFilesForm(props: { userID?: string }) {
  const { userID } = props;
  const [urlDialogOpen, setUrlDialogOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleUrlDialogOpen = () => {
    setUrlDialogOpen(true);
  };

  const handleUrlDialogClose = () => {
    setUrlDialogOpen(false);
  };

  const urlForm = useFormik({
    initialValues: {
      url: '',
    },
    validationSchema: yup.object({
      url: yup
        .string()
        .required('URL is required')
        .test('is-url-exist', 'Please enter a valid URL', (value) => (value ? isUrlValid(value) : false)),
    }),
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);
      const userId = getUserID(userID);

      try {
        await sendUserMessage(userId, values.url, CHANNEL_KEY, getClientData());
      } catch {
        enqueueSnackbar("Can't load files. Please try again.", { variant: 'error' });
      }

      handleUrlDialogClose();
      actions.resetForm();
    },
  });

  const handleFileUpload = async (files: Array<File> | null) => {
    setLoading(true);
    if (files && files.length > 0) {
      const userId = getUserID(userID);

      try {
        await uploadDocuments(userId, CHANNEL_KEY, files);
      } catch {
        enqueueSnackbar("Can't load documents. Please try again.", { variant: 'error' });
      }
    }
    setLoading(false);
  };

  return (
    <>
      <Stack spacing={2} sx={{ paddingTop: 2, paddingBottom: 2 }}>
        <Stack direction="row" spacing={1}>
          <MultiFileUploader sx={{ flexGrow: 1 }} onChange={handleFileUpload}>
            <Button
              sx={{
                width: '100%',
                backgroundColor: '#232F48',
                '&:hover': {
                  backgroundColor: '#1c2538',
                },
              }}
              startIcon={<ContentCopyOutlinedIcon sx={{ color: '#7BB3F8' }} />}
              size="large"
              disabled={loading}
            >
              Upload File
            </Button>
          </MultiFileUploader>
          <Button
            sx={{
              flexGrow: 1,
              backgroundColor: '#232F48',
              '&:hover': {
                backgroundColor: '#1c2538',
              },
            }}
            onClick={handleUrlDialogOpen}
            startIcon={<LinkOutlined sx={{ color: '#7BB3F8' }} />}
            size="large"
            color="secondary"
            disabled={loading}
          >
            Add Website
          </Button>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <InfoOutlinedIcon sx={{ fontSize: 16, color: '#778BAE' }} />
          <Typography sx={{ fontSize: { xs: 12 }, fontWeight: 600, color: '#778BAE' }}>
            File type: pdf, docx (20MB max)
          </Typography>
        </Stack>
      </Stack>
      <UrlDialog
        // style={{ backgroundColor: 'red' }}
        // sx={{ backgroundColor: '#192337' }}
        open={urlDialogOpen}
        fullWidth
        maxWidth="sm"
        onClose={handleUrlDialogClose}
      >
        <form onSubmit={urlForm.handleSubmit}>
          <Stack spacing={3} sx={{ padding: { xs: '20px', lg: '10% 15%' } }}>
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Aeonik Fono',
                fontSize: { xs: 22 },
                fontWeight: 700,
                color: '#CED9E9',
              }}
            >
              Add Website
            </Typography>
            <TextField
              name="url"
              onChange={urlForm.handleChange}
              value={urlForm.values.url}
              label="URL"
              size="small"
              variant="outlined"
              autoComplete="url"
              placeholder="https://"
              disabled={urlForm.isSubmitting}
              error={urlForm.touched.url && Boolean(urlForm.errors.url)}
              helperText={urlForm.touched.url && urlForm.errors.url}
            />
            <Stack spacing={1}>
              <Button
                sx={{
                  backgroundColor: '#2676E7',
                }}
                type="submit"
                size="large"
                disabled={urlForm.isSubmitting}
              >
                Submit
              </Button>
              <Button
                sx={{
                  borderColor: '#A8B8D2',
                  color: '#A8B8D2',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    borderColor: '#8a95af',
                  },
                }}
                size="large"
                variant="outlined"
                onClick={handleUrlDialogClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </UrlDialog>
    </>
  );
}

const MAILS_BLACKLIST: string[] = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'gmx.com',
  'protonmail.com',
  'yandex.com',
  'zoho.com',
  'mail.ru',
  'qq.com',
  'gamil.com',
  'gmail.co',
];

export function UserEmailForm() {
  const navigate = useNavigateWithQuery();
  const { search } = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const { uploadedResources: resources } = useSelector<AppState, State>((state) => state?.app);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Enter a valid email')
        .test(
          'Corporate',
          'Please use a valid corporate email address',
          (email) => !!email && !MAILS_BLACKLIST.some((bannedEmail) => email.includes(bannedEmail)),
        )
        .required('Email is required'),
    }),
    onSubmit: async ({ email }) => {
      try {
        const { isExists, isActive } = await checkEmail(email, search.slice(1));
        setUserEmail(email);

        if (!isExists) {
          await sentConfirmation(email);

          // TODO: ????
          const event = new CustomEvent('testmybot-click', { bubbles: true, cancelable: true });
          window.dispatchEvent(event);

          await uploadResources(email, resources);
          navigate('/steps/confirm');
        } else if (isExists && !isActive) {
          enqueueSnackbar('Your account has already been created, please check your email', { variant: 'error' });
        } else if (isExists && isActive) {
          window.location.assign(NODE_VARS.PLATFORM_URL);
        }
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });
  return (
    <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
      <Stack spacing={2}>
        <Typography sx={{ color: '#CED9E9', fontSize: { xs: 16 }, fontFamily: 'Aeonik Fono', fontWeight: 700 }}>
          Enter Work Email to Use Bot
        </Typography>
        <TextField
          name="email"
          label="Work Email"
          size="small"
          variant="outlined"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          required
          disabled={formik.isSubmitting}
        />
        <Button type="submit" disabled={formik.isSubmitting} size="large">
          Talk to Bot
        </Button>
      </Stack>
      <LoadingBackdrop loading={formik.isSubmitting} />
    </form>
  );
}
