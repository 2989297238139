import { NODE_VARS } from '@metadialog/landing-chat-config';

export const INITIAL_HTML = `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>DEMO</title>
    <script defer="true" src="${NODE_VARS.WIDGET_URL}/index.js"></script>
  </head>
  <body>
    <div id="mountHere"></div>
  </body>
</html>`;

export const CHAT_BOT_READY = 'chatbotready';
export const CHANNEL_KEY = 'web-metadialog-onboarding-test-a2a7-4ffc-96ba-5282f642a82c';
